import React from "react";
import { asyncComponent } from "@shopdeck/after";

export default [

	{
		path: "/styleguide",
		Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Styleguide" */ "./pages/Styleguide"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Styleguide"
    }),
	},
  {
    path: "/",
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Home" */ "./pages/Home"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Home"
    }),
  },
	{
    path: "/about-us",
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "about-us" */ "./pages/AboutUs"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "about-us"
    }),
  },
	{
    path: "/book-demo-form",
    Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "book-demo-form" */ "./pages/BookDemoForm"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "book-demo-form"
    }),
  },
  {
		path: "/terms",
		Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "TermsAndConditions" */ "./pages/TermsAndConditions"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "TermsAndConditions"
    }),
	},
  {
		path: "/privacyPolicy",
		Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "PrivacyPolicy"
    }),
	},
	{
		path: "/privacy-policy",
		Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "PrivacyPolicy"
    }),
	},
	{
		path: "/careers",
		Component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Careers" */ "./pages/Careers"),
      Placeholder: () => <div>...LOADING...</div>,
      chunkName: "Careers"
    }),
	},
];
