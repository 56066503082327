import React from "react";
import { firePageLoadEvent } from "./events/pageLoad";
import usePageView from "./hooks/usePageView";
import { defaultFallbackInView } from 'react-intersection-observer';
import { ScrollRestoration, useLocation } from "react-router-dom";
import ScrollToHash from "atoms/ScrollToHash";
import { isClient } from "utils/helper";
defaultFallbackInView(true);


export const App = ({ children }) => {
	const { previousLocation } = usePageView();

	React.useEffect(() => {
		firePageLoadEvent();
	}, [previousLocation]);

	return (
		<>
			{/* {isClient && <ScrollToHash />} */}
			{children}
		</>
	);
};
